import ReactBreadcrumbs from 'react'
import Breadcrumbs from '../../components/Breadcrumbs'
import Input from '../../formGroup/Input'
import axios from 'axios'
import Spinner from '../../page/facultydashboard/formGroup/Spinner'
import React, { useState } from 'react'
import { message } from 'antd'
function SiteTittle() {
  const [siteLogo, setSiteLogo] = useState('')
  const [siteTitle, setSiteTitle] = useState('')

  // get data
  const [data, setData] = React.useState({})
  const [loader, setLoader] = React.useState(false)
  // console.log(data)
  const siteData = () => {
    setLoader(true)
    axios.get('/api/v1/api/site/data')
      .then(function (response) {
        // handle success
        // console.log(response);
        setLoader(false)
        setData(response.data[0])
        setSiteTitle(response.data[0].siteName)
      })
      .catch(function (error) {
        // handle error
        console.log(error);
        setLoader(false)
      })
  }

  //  change logo
  const logoChangeFun = (event) => {
    event.preventDefault()
    if (!siteLogo) {
      message.error('Please Select Image First')
    }
    else {
      const formData = new FormData();
      formData.append('siteLogo', siteLogo);
      setLoader(true)
      axios.put(`/api/v1/api/site/header/${data._id}`, formData,)
        .then(function (response) {
          setLoader(false)
          message.success(response.data.message)

          siteData()
        })
        .catch(function (error) {
          setLoader(false)
          message.error(error.response.data.errors)

        });
    }

  }
  //  change site name
  const changesiteName = (event) => {
    event.preventDefault()

    const formData = new FormData();
    formData.append('siteName', siteTitle);
    setLoader(true)
    axios.put(`/api/v1/api/site/header-name/${data._id}`, {
      siteName: siteTitle
    },)
      .then(function (response) {
        setLoader(false)
        message.success(response.data.message)
        siteData()
      })
      .catch(function (error) {
        setLoader(false)
        message.error(error.response.data.errors)
      });


  }

  // change site title


  React.useEffect(() => {
    siteData()
  }, [])
  return (
    <div className=''>
      {
        loader && <Spinner />
      }
      <Breadcrumbs page={"Site Title"} pagelink="#" sublink='#' subpage={"Site Setting"} />
      <div className='  grid'>



        <div className='ml-4 sm:flex gap-1'>
          <img className="object-cover rounded-full w-[7rem] bg-gray-500 aspect-square" src={`/api/v1/media/${data?.siteLogo}`} />
          <div className='my-auto'>
            <h1 className=''>Replace logo</h1>

            <input type='file' onChange={(e) => { setSiteLogo(e.target.files[0]) }} className="inline-block  mx-auto px-2 py-1 rounded border border-[#273272] mt-2  text-sm font-medium text-[#273272] hover:bg-[#273272] hover:text-white focus:outline-none focus:ring active:bg-indigo-500" />
            <button className='inline-block mt-1  px-4 py-2 rounded border border-[#273272]   text-sm font-medium text-[#273272] hover:bg-[#273272] hover:text-white focus:outline-none focus:ring active:bg-indigo-500' onClick={logoChangeFun}>update</button>

          </div>
          <div className='flex justify-center space-x-2'>


          </div>
        </div>
        <div className='grid gap-4 mx-8 grid-cols-3 my-auto'>

          <div className="">
            <label

              className={`block mb-2 text-sm font-medium text-gray-900  `}
            >
              Site Title
              <span className='text-red-500 font-bold text-[1.2rem]'>*</span>
            </label>
            <input
              type='text'

              value={siteTitle}
              onChange={(e) => { setSiteTitle(e.target.value) }}
              className={`w-full h-9 mt-2 rounded-sm border focus:border-gray-700 p-3 text-sm focus:outline-none focus:bg-white bg-gray-200 `}
              placeholder=""
            // required='true'
            />
          </div>
          <div className='my-auto'>
            <h1 className='opacity-0'>k</h1>
            <button className='inline-block mt-1  px-4 py-2 rounded border border-[#273272]   text-sm font-medium text-[#273272] hover:bg-[#273272] hover:text-white focus:outline-none focus:ring active:bg-indigo-500' onClick={changesiteName}>update</button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SiteTittle