import React, { useState, useEffect } from "react";
import axios from "axios";
import Loader from "./formGroup/Spinner";
import Alert from "../../formGroup/Alert";
import Confirm from "../../formGroup/Confirm";
import { message } from "antd";
import logo from "../../assests/NewLogo.png";
import { useNavigate } from "react-router-dom";
import { MdOutlineVisibility, MdOutlineVisibilityOff } from "react-icons/md";

// import {message} from 'antd'
import { Link } from "react-router-dom";
// import {useNavigate} from 'react-router-dom'
function FacultyLogin() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  // console.log(email, password)
  const [alert, setAlert] = useState(false);
  const [successalert, setSuccessAlert] = useState(false);
  const [messages, setMessage] = useState("");
  const navigate = useNavigate();

  const [datas, setData] = React.useState({});
  const [loader, setLoader] = useState(true);
  // console.log(datas)
  const siteData = () => {
    axios
      .get("/api/v1/api/site/data")
      .then(function (response) {
        // handle success
        // console.log(response);
        setData(response.data[0]);
        setLoader(false);
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      });
  };
  React.useEffect(() => {
    siteData();
  }, []);

  const onSubmit = (e) => {
    e.preventDefault();
    axios
      .post("/api/v1/login", {
        email: email,
        password: password,
      })
      .then(function (response) {
        // console.log(response);
        // alert(response.data.message);
        // console.log({role:response.data.role});
        if (response.data.role === 0) {
          navigate("/faculty-dashboard");
        }
        if (response.data.role === 1) {
          navigate("/admin/dashboard");
        }
        if (response.data.role === 2) {
          navigate("/admin/dashboard");
        }
        message.success(response.data.message);
        // alert('response.data.message').
        // setState({ ...intialValue })
      })
      .catch(function (error) {
        console.log(error);
        // console.log(error.response.data.message);
        message.error(error.response.data.message);
      });
  };

  const closeModal = () => {
    setAlert(false);
    setSuccessAlert(false);
  };
  const [passwordvisible, setPasswordvisible] = useState("");
  const [showPasswordvisible, setShowPasswordvisible] = useState(false);

  const handlePasswordToggle = () => {
    setShowPasswordvisible(!showPasswordvisible);
  };
  return (
    <div className="flex h-screen bg-[#000428]">
      {loader && <Loader />}
      {alert && <Alert message={message} closeModal={closeModal} />}
      {successalert && <Confirm message={message} closeModal={closeModal} />}

      {/* <Confirm message={message} closeModal={closeModal}/> */}

      <div className="flex m-auto flex-col w-5/6 md:w-full max-w-md p-12 space-y-4 text-center bg-white text-gray-800">
        {/* <h1 className="text-3xl  font-semibold">Sign in to your account</h1>
				<a className="text-sm text-gray-600" href="/">BNMU login</a> */}
        <div className="flex justify-center">
          <img
            src={`/api/v1/media/${datas?.siteLogo}`}
            alt=""
            className="h-20 w-20 "
          />
        </div>
        <div
          noValidate=""
          className="space-y-4 ng-untouched ng-pristine ng-valid"
        >
          <div className="flex gap-1 flex-col">
            <label htmlFor="email" className="sr-only">
              Email address
            </label>
            <input
              required
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
              id="email"
              type="email"
              placeholder="Email address"
              className="rounded-t-md border-gray-400 bg-gray-50 text-gray-800 focus:ring-[#000428] focus:border-[#000428] focus:ring-2"
            />
            <label htmlFor="password" className="sr-only">
              Password
            </label>
            {/* <input value={password} onChange={(e) => { setPassword(e.target.value) }} required id="password" type="password" placeholder="Password" className="-mt-1 rounded-b-md border-gray-400 bg-gray-50 text-gray-800 focus:ring-[#000428] focus:border-[#000428] focus:ring-2" /> */}

            <input
              type={showPasswordvisible ? "text" : "password"}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="rounded-b-md border-gray-400 bg-gray-50 text-gray-800 focus:ring-[#000428] focus:border-[#000428] focus:ring-2"
            />
            <button
              onClick={handlePasswordToggle}
              className="relative text-xl "
            >
              <span className="absolute right-2 bottom-3">
                {showPasswordvisible ? (
                  <MdOutlineVisibilityOff />
                ) : (
                  <MdOutlineVisibility />
                )}
              </span>
            </button>
          </div>
          <div className="flex justify-between">
            <div className="flex items-center">
              <input
                type="checkbox"
                name="remember"
                id="remember"
                aria-label="Remember me"
                className="mr-1 rounded-sm focus:ring-[#000428] focus:border-[#000428] focus:ring-2 accent-[#000428]"
              />
              <label htmlFor="remember" className="text-sm text-black">
                Remember me
              </label>
            </div>

            <Link className="text-sm text-black" to="/forgotpassword">
              Forgot your password?
            </Link>
          </div>
          <button
            type="submit"
            onClick={onSubmit}
            className="px-8 py-3 space-x-2 font-semibold rounded bg-[#000428] text-gray-50"
          >
            Sign in
          </button>
        </div>
      </div>
    </div>
  );
}

export default FacultyLogin;
